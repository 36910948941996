<template>
  <div class="mainbox">
    <div class="header">
      <div @click="$router.go(-1)">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="title">{{ deatil.title }}</div>
    </div>
    <div class="content overflow-y-auto">
      <!-- 视频 -->
      <div class="cover" v-if="type == 'video'" @click="showPlayVideo = true">
        <ImgDecypt :src="deatil.cover" class="modCover">
          <div class="play_box">
            <svg-icon iconClass="playIcon"></svg-icon>
          </div>
        </ImgDecypt>
      </div>
      <!-- 图片 -->
      <div class="changImage" v-if="type == 'image'">
        <ImgDecypt :src="deatil.cover" class="modCover" />
      </div>
      <div class="mess">
        <h2>注意事项：</h2>
        <p>
          1.
          选择一张人脸清晰，不得有任何遮挡的照片上传（注意：只含一个人物和脸部，图片不能过暗）
        </p>
        <p>
          2.
          选择一个心仪的视频或图片模板，点击生成，生成时间需要3-5分钟，耐心等待。（图片模板可自行上传）
        </p>
        <p>
          3.
          在右上角记录查看生成进度，生成成功后可以点击进行下载，也可以在线观看。
        </p>
        <p>4. 按照上方操作，有问题随时联系在线客服进行处理。</p>
        <p>5. 不支持多人图片，禁止未成年人图片</p>
      </div>
      <div class="imgmess">
        <div>
          <img src="@/assets/png/aiTip1.png" alt="" />
          正面无遮挡
        </div>
        <div>
          <img src="@/assets/png/aiTip2.png" alt="" />
          不遮挡脸部
        </div>
        <div>
          <img src="@/assets/png/aiTip3.png" alt="" />
          不遮挡眼睛
        </div>
      </div>
      <div class="title">上传脸部信息</div>
      <div @click.stop>
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          :before-read="beforeRead"
          :before-delete="beforeDelete"
        >
          <template #default>
            <div class="uploadBox flex-center-center">
              <img src="@/assets/png/aiUploadBg.png" alt="" />
              图片大小低于2MB
            </div>
          </template>
          <template #preview-delete>
            <svg-icon
              iconClass="previewDelete"
              class="previewDelete"
            ></svg-icon>
          </template>
        </van-uploader>
      </div>
      <div class="subBtn" @click="onSubmit" v-if="type == 'video'">
        {{
          this.userInfo.isVip ? this.deatil.vipCoin : this.deatil.coin
        }}金币/支付
      </div>
      <!-- <div class="freeCount" v-if="this.type == 'image'">免费次数：{{ this.freeCount || 0 }}</div> -->
      <div class="subBtn" @click="onSubmit" v-if="type == 'image'">
        {{ this.aiUndressPrice || 0 }}金币/支付
      </div>
    </div>
    <!-- <div class="bottom">
      <span v-if="this.type == 'video'">
        消耗金币:
        {{ this.userInfo.isVip ? this.deatil.vipCoin : this.deatil.coin }}
      </span>
      <span v-else>
        消耗金币:
        {{ this.aiUndressPrice || 0 }}
      </span>
      <span v-if="this.type == 'image'"
        >免费次数：{{ this.freeCount || 0 }}</span
      >
      <div @click="onSubmit">生成</div>
    </div> -->

    <van-overlay :show="this.showPlayVideo" z-index="9999">
      <svg-icon
        iconClass="navArrow"
        class="close"
        @click="this.showPlayVideo = false"
      ></svg-icon>
      <div class="wrapper blackBg">
        <SmallVideo
          v-if="this.showPlayVideo"
          class="videoBox"
          :sourceURL="this.deatil.sourceURL"
          :key="this.deatil.sourceURL"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast, Uploader } from "vant";
// import { uploadImg } from "@/api/app";
import {
  generateImg,
  getAiMod,
  uploadFile,
  generateChangeVideo,
  generateChangeImg,
} from "@/api/mine";
import { mapGetters } from "vuex";
import ImgDecypt from "@/components/ImgDecypt";
import SmallVideo from "@/components/SmallVideo";
export default {
  name: "changeDetail",
  components: {
    ImgDecypt,
    [Uploader.name]: Uploader,
    SmallVideo,
  },
  data() {
    return {
      showPlayVideo: false,
      uploadImages: [],
      fileSize: 500 * 1024,
      fileList: [],
      freeCount: 0,
      totalPrice: 0, // ai脱衣总售价
      aiUndressPrice: 0, // ai脱衣的售价
      deatil: {},
      timer: "",
      type: "",
    };
  },
  computed: {
    ...mapGetters({
      walletInfo: "walletInfo",
      userInfo: "userInfo",
    }),
    balance() {
      return (this.walletInfo?.amount || 0).toFixed(1);
    },
  },
  created() {
    this.aiUndressPrice = this.$store.getters.appConfig.aiChangeFaceImgPrice || 0;
    // this.freeCount = this.userInfo.aiUndressCount || 0;
  },
  mounted() {
    this.getDeatil();
  },
  methods: {
    beforeRead(file) {
      if (file.size > this.fileSize) {
        Toast("文件不可超过2MB");
        return false;
      }
      return true;
    },
    // 获取详情
    async getDeatil() {
      this.deatil = JSON.parse(localStorage.getItem("deatilData"));
      this.type = this.$route.query.type;
    },
    // 图片上传
    async afterRead(file) {
      if (file.length) {
        file.map(async (item) => {
          let newFile = await this.compress(item.file);
          item.file = newFile;
          await this.uploadImg(item);
        });
      } else {
        let newFile = await this.compress(file.file);
        file.file = newFile;
        this.uploadImg(file);
      }
    },
    //图片上传
    async uploadImg(fileInfo) {
      let req = new FormData();
      req.append("upload", fileInfo.file);
      fileInfo.status = "uploading";
      fileInfo.message = "上传中...";
      try {
        let ret = await this.$Api(uploadFile, req);
        const { data } = ret;
        fileInfo.status = "done";
        fileInfo.message = "上传成功";
        fileInfo.status = "";
        fileInfo.message = "";
        this.uploadImages.push(data.coverImg);
        this.totalPrice = this.showPrice(
          this.uploadImages.length,
          this.aiUndressPrice
        );
      } catch (e) {
        fileInfo.status = "failed";
        fileInfo.message = "上传失败";
      }
    },
    // 压缩图片
    compress(file) {
      let selt = this;
      if (!file || !window.FileReader) return;
      if (/^image/.test(file.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          reader.onloadend = function () {
            let result = reader.result;
            let img = new Image();
            img.src = result;
            if (result.length <= 200 * 1024) {
              let blob = selt.convertBase64ToBlob(result);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
              return;
            }
            img.onload = function () {
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              let width = img.width;
              let height = img.height;
              canvas.width = width;
              canvas.height = height;
              // 铺底色
              ctx.fillStyle = "#fff";
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, width, height);
              //进行最小压缩
              let ndata = canvas.toDataURL("image/jpeg", 0.3);
              let blob = selt.convertBase64ToBlob(ndata);
              let files = new File([blob], file.name, { type: blob.type });
              resolve(files);
            };
          };
        });
      }
    },
    //将base64转换为文件对象
    convertBase64ToBlob(base64) {
      var base64Arr = base64.split(",");
      var imgtype = "";
      var base64String = "";
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(
          base64Arr[0].indexOf(":") + 1,
          base64Arr[0].indexOf(";")
        );
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], { type: imgtype });
    },
    // 获取脱衣后的图片
    async getGenerateImg() {
      if (!this.userInfo.isVip) return Toast("请先充值vip");
      if (!this.uploadImages.length) return Toast("至少上传一张图片");
      let req = {
        originPic: this.uploadImages[0],
        mId: this.deatil.id,
        coin: this.totalPrice,
      };
      try {
        let res = await this.$Api(generateChangeImg, req);
        console.log(res);
        Toast("上传成功，等待审核");
        this.fileList = [];
        this.uploadImages = [];
        if (this.freeCount <= 0) {
          this.walletInfo.amount -= this.totalPrice;
        }
        this.totalPrice = 0;
      } catch (e) {
        console.log(e);
      }
    },
    // async getGenerateImg() {
    //   if (!this.userInfo.isVip) {
    //     this.$bus.$emit("vipPopup", {
    //       this: 1,
    //       closeBtn: () => {
    //         this.$bus.$emit("closeVipPopup");
    //       },
    //     });
    //     return;
    //   }
    //   if (!this.uploadImages) return Toast("请先上传一张图片");
    //   if (this.freeCount <= 0 && Number(this.aiUndressPrice) > this.balance)
    //     return Toast("免费次数与账户余额不足");
    //   let req = {
    //     originPic: this.uploadImages[0],
    //     mId: this.deatil.id,
    //    coin: this.totalPrice,
    //    // coin: Number(this.aiUndressPrice),
    //   };
    //   let res = await this.$Api(generateChangeImg, req);
    //   if (res && res.code == 200) {
    //     Toast("上传成功，等待审核");
    //    this.fileList = [];
    //    this.uploadImages = "";
    //     if (this.freeCount > 0) {
    //       this.freeCount--;
    //     }
    //   } else {
    //     Toast(res.tip || "上传失败");
    //   }
    // },
    // 根据索引删除数组
    delElbyIndex(arr, index) {
      for (let i = index, len = arr.length - 1; i < len; i++) {
        arr[i] = arr[i + 1];
        arr.length = len;
      }
      return arr;
    },
    async beforeDelete(file, detail) {
      const newArr = await this.delElbyIndex(this.uploadImages, detail.index);
      this.totalPrice = 0; //showPrice(newArr.length, this.aiUndressPrice);
      this.uploadImages = newArr;
      return true;
    },
    // 价格优惠
    showPrice(quantity, price) {
      let totalPrice = 0;
      totalPrice = quantity * price;
      return Math.ceil(totalPrice);
    },
    // ai视频
    async getGenerateVideo() {
      if (!this.uploadImages.length) return Toast("至少上传一张图片");
      if (!this.userInfo.isVip) return Toast("请先充值vip");
      let req = {
        picture: this.uploadImages,
        vidModId: this.deatil.id,
      };
      try {
        let res = await this.$Api(generateChangeVideo, req);
        console.log(res);
        Toast("上传成功，等待审核");
        this.fileList = [];
        this.uploadImages = [];
        this.walletInfo.amount -= this.totalPrice;
        this.totalPrice = 0;
      } catch (e) {
        console.log(e);
      }
    },
    onSubmit() {
      this.debounce(
        this.type == "video" ? this.getGenerateVideo : this.getGenerateImg,
        500
      );
    },
    debounce(fn, wait) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        fn();
      }, wait);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  width: 100%;
  height: 100%;
}
.header {
  height: 44px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  .back {
    width: 30px;
    height: 30px;
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #333333;
    font-size: 18px;
    max-width: 220px;
    font-weight: 600;
    margin-left: 20px;
  }
}
.content {
  overflow: auto;
  height: calc(100vh - 44px);
  // background: #f1f1f1;
  width: 100%;
  padding: 0 15px 160px 15px;
  box-sizing: border-box;
  .changImage {
    width: 100%;
    height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    .modCover {
      width: 150px;
      height: 100%;
    }
  }
  .cover {
    width: 100%;
    height: 193px;
    margin: 16px 0;
    .modCover {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .play_box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        width: 50px;
        height: 50px;
      }
    }
  }
  .mess {
    h2 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin: 8px 0;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      color: rgb(153, 153, 153);
    }
  }
  .imgmess {
    margin: 22px 0;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      font-size: 14px;
      color: rgb(153, 153, 153);
      img {
        width: 100px;
        height: 100px;
        margin-top: 14px;
      }
    }
  }
  .title {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
}
/deep/ .van-uploader {
  width: 111px;
  height: 111px;
  .van-uploader__wrapper {
    width: 100%;
    height: 100%;
    border: dashed 1px #999999;
    border-radius: 5px;
    overflow: hidden;
    background: #ebebeb;
  }
  .van-uploader__input-wrapper,
  .uploadBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
    }
    font-size: 12px;
    color: #999999;
  }
}
.precautionsBox {
  h2 {
    font-size: 14px;
    color: #333333;
    margin: 6px 0;
    font-weight: 500;
  }
  margin-left: 20px;
  font-size: 12px;
  line-height: 16.8px;
  color: #999999;
}
.close {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10000;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  background: rgb(0, 0, 0);
  .videoBox {
    height: 100%;
    width: 100%;
    /deep/ .headBar {
      background: #000 !important;
    }
    // /deep/ .leftIcon {
    //   color: #fff !important;
    // }
  }
}
.bottom {
  width: 100%;
  height: 91px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 10px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fafafa;
  > div {
    width: 114px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 22px;
    background: #94d6da;
    color: #ffffff;
  }
  > span {
    color: #333333;
    font-size: 14px;
  }
}
/deep/ .van-uploader__preview {
  width: 116px;
  height: 116px;
  border-radius: 4px;
  overflow: hidden;
  margin: unset;
  .van-uploader__preview-image {
    width: 100%;
    height: 100%;
  }
}
.freeCount {
  // position: fixed;
  // left: 0;
  // bottom: 46px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 12px;
  color: #333;
}
.subBtn {
  width: 269px;
  height: 46px;
  // margin-top: 20px;
  color: #333;
  background: #fbd13d;
  border-radius: 46px;
  margin: 12px auto;
  // position: fixed;
  // left: 50%;
  // bottom: 46px;
  // transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}
</style>
